import { Snackbar, SxProps, Theme } from '@mui/material';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';

interface Option {
  contentSx?: SxProps<Theme>;
  action?: React.ReactElement;
}

const openAtom = atom(false);
const messageAtom = atom<string | React.ReactElement>('');
const msgOptionAtom = atom<Option>({});
const showMessageAtom = atom(
  null,
  (_get, set, msg: string | React.ReactElement, options?: Option) => {
    set(messageAtom, msg);
    set(openAtom, true);
    if (options) {
      set(msgOptionAtom, options);
    } else {
      set(msgOptionAtom, {});
    }
  }
);

const hideMessageAtom = atom(null, (_get, set) => {
  set(messageAtom, '');
  set(openAtom, false);
  set(msgOptionAtom, {});
});

export default function GlobalSnackbar() {
  const [open, setOpen] = useAtom(openAtom);
  const [message] = useAtom(messageAtom);
  const msgSxAtomValue = useAtomValue(msgOptionAtom);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      key={typeof message === 'string' ? message : ''}
      ContentProps={{ sx: [{ width: 800 }, msgSxAtomValue.contentSx as any] }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      action={msgSxAtomValue.action}
    />
  );
}

export const useShowSnackbarMessage = () => useSetAtom(showMessageAtom);
export const useHideSnackbarMessage = () => useSetAtom(hideMessageAtom);

// TODO: deprecated -> use useShowSnackbarMessage() directly instead
export const useSnackbar = () => {
  const showMessage = useShowSnackbarMessage();
  const hideMessage = useHideSnackbarMessage();
  return { showMessage, hideMessage };
};
