/**
 * This file contains all the utils related to date and time
 * There is a strict relation between locales, utils and time-utils
 * This separation helps resolve circular depts between those files
 */
import {
  format,
  formatDuration as formatDurationDateFns,
  intervalToDuration,
  isToday,
  parseISO,
} from 'date-fns';
import useLocaleCfg, {
  Bcp47,
  DurationUnits,
} from '@/common/hooks/use-locale-cfg';
import { useMemo } from 'react';
import useCallbackRef from '@/common/hooks/use-callback-ref';
import { useTranslation } from 'next-i18next';
import { isSimplifiedZh, isTraditionalZh } from './locales.cjs';
import { isProduction } from './utils';

export default function useI18nTimeUtils() {
  const locale = useLocaleCfg();
  const { t } = useTranslation();

  const compactDuration = useCallbackRef(
    (duration: string, units?: Partial<DurationUnits>) => {
      const du: DurationUnits = {
        years: ({ digit }) => [
          t('{{digit}}yr', { digit }),
          t('{{digit}}yrs', { digit }),
        ],
        months: ({ digit }) => [
          t('{{digit}}mo', { digit }),
          t('{{digit}}mos', { digit }),
        ],
        weeks: ({ digit }) => [
          t('{{digit}}wk', { digit }),
          t('{{digit}}wks', { digit }),
        ],
        days: ({ digit }) => [
          t('{{digit}}d', { digit }),
          t('{{digit}}d', { digit }),
        ],
        hours: ({ digit }) => [
          t('{{digit}}h', { digit }),
          t('{{digit}}h', { digit }),
        ],
        minutes: ({ digit }) => [
          t('{{digit}}m', { digit }),
          t('{{digit}}m', { digit }),
        ],
        seconds: ({ digit }) => [
          t('{{digit}}s', { digit }),
          t('{{digit}}s', { digit }),
        ],
        ...units,
      };
      return (
        duration
          .replace(
            /(\d+)\syear\b/i,
            (match, digit) => du.years({ match, digit })[0]
          )
          .replace(
            /(\d+)\syears\b/i,
            (match, digit) => du.years({ match, digit })[1]
          )
          .replace(
            /(\d+)\smonth\b/i,
            (match, digit) => du.months({ match, digit })[0]
          )
          .replace(
            /(\d+)\smonths\b/i,
            (match, digit) => du.months({ match, digit })[1]
          )
          .replace(
            /(\d+)\sday\b/i,
            (match, digit) => du.days({ match, digit })[0]
          )
          .replace(
            /(\d+)\sdays\b/i,
            (match, digit) => du.days({ match, digit })[1]
          )
          .replace(
            /(\d+)\shour\b/i,
            (match, digit) => du.hours({ match, digit })[0]
          )
          .replace(
            /(\d+)\shours\b/i,
            (match, digit) => du.hours({ match, digit })[1]
          )
          .replace(
            /(\d+)\sminute\b/i,
            (match, digit) => du.minutes({ match, digit })[0]
          )
          .replace(
            /(\d+)\sminutes\b/i,
            (match, digit) => du.minutes({ match, digit })[1]
          )
          // 1 second -> 1s
          .replace(
            /(\d+)\ssecond\b/i,
            (match, digit) => du.seconds({ match, digit })[0]
          )
          // 2 seconds -> 2s
          .replace(
            /(\d+)\sseconds\b/i,
            (match, digit) => du.seconds({ match, digit })[1]
          )
      );
    }
  );

  const formatSecondToTime = useCallbackRef((seconds: number) =>
    compactDuration(
      formatDuration(
        intervalToDuration({
          start: 0,
          end: seconds * 1000,
        })
      ),
      {
        minutes: ({ digit }) => [
          t('{{digit}}min', { digit }),
          t('{{digit}}mins', { digit }),
        ],
      }
    )
  );

  const secondsToHrsMinsDecimal = useCallbackRef(
    (seconds: number | null | undefined) => {
      if (!seconds) return '';
      const { hours = 0, minutes = 0 } = intervalToDuration({
        start: 0,
        end: seconds * 1000,
      });

      return compactDuration(
        formatDuration({
          minutes: !hours ? minutes : 0,
          hours: !hours ? 0 : hours + minutes / 60,
        })
      );
    }
  );

  const secondsToHrsMins = useCallbackRef(
    (seconds: number | null | undefined) => {
      if (!seconds) return '';
      const { hours = 0, minutes = 0 } = intervalToDuration({
        start: 0,
        end: seconds * 1000,
      });

      return compactDuration(
        formatDuration({
          minutes,
          hours,
        })
      );
    }
  );
  /**
   * Sort the day value array to oder: Sun, Mon, Tue, Wed, Thu, Fri, Sat.<br/>
   * The value got from back-end.<br/>
   *   sunday: { value: 0, order: 1 },
   *   monday: { value: 1, order: 2 },
   *   tuesday: { value: 2, order: 3 },
   *   wednesday: { value: 3, order: 4 },
   *   thursday: { value: 4, order: 5 },
   *   friday: { value: 5, order: 6 },
   *   saturday: { value: 6, order: 7 },
   * Back-end uses this order to calculate the label, i.e.:
   *   0,1,2,3,4,5,6: Mon - Sun
   *   0,1,2,3,4,6: Mon - Fri, Sun
   *   0,1,2,4,5,6: Mon - Tue, Thu - Sun
   */
  const sortDayInWeek = useCallbackRef((dayValueArr: number[]) => {
    return dayValueArr.sort((a, b) => a - b);
  });

  /**
   * This function is use for formatting date to show in UI
   * DO NOT USE for url formatting, i.e. date params in CRUD
   *
   * @param date
   * @param formats
   */
  const formatDate = useCallbackRef(
    (
      date: number | Date,
      formats: Partial<Record<Bcp47, string>> & {
        en: string;
        zh?: string;
        /**
         * Simplified Chinese
         */
        zhHans?: string;
        /**
         * Traditional Chinese
         */
        zhHant?: string;
      }
    ) => {
      let fmt: string | undefined;
      let fallbackFmt: string | undefined;
      if (locale['BCP 47']) {
        fmt = formats[locale['BCP 47']];
        if (isTraditionalZh(locale['BCP 47']))
          fallbackFmt = formats.zhHant ?? formats.zh;
        if (isSimplifiedZh(locale['BCP 47']))
          fallbackFmt = formats.zhHans ?? formats.zh;
      }
      try {
        return format(date, fmt ?? fallbackFmt ?? formats.en, {
          locale: locale.dateLocale,
        });
      } catch (err) {
        if (isProduction()) {
          console.error(err);
          return 'Invalid Date';
        }
        throw err;
      }
    }
  );

  /**
   * This function is use for formatting duration to show in UI
   * DO NOT USE for url formatting, i.e. date params in CRUD
   * @param date
   * @param duration
   */
  const formatDuration = useCallbackRef(
    (
      duration: Duration,
      options?: {
        format?: string[] | undefined;
        zero?: boolean | undefined;
        delimiter?: string | undefined;
        locale?: Locale | undefined;
      }
    ) =>
      formatDurationDateFns(duration, { locale: locale.dateLocale, ...options })
  );

  /**
   * Convert 24-hour time to 12-hour time
   * @param time, i.e 18:00:00
   */
  const to12HourBase = useCallbackRef((time: string) => {
    return parseISO('1970-01-01T' + time + 'Z').toLocaleTimeString(
      locale['BCP 47'] || 'en-US',
      {
        timeZone: 'UTC',
        hour12: true,
        hour: 'numeric',
        minute: 'numeric',
      }
    );
  });

  const formatLongDateTime = useCallbackRef((date: Date) =>
    [
      isToday(date) && t('Today') + ',',
      formatDate(date, {
        en: 'd LLL yyyy, h:mm a',
        zh: 'yyyy LLL do, h:mm a',
      }),
    ]
      .filter(Boolean)
      .join(' ')
  );

  return useMemo(
    () => ({
      formatSecondToTime,
      secondsToHrsMinsDecimal,
      secondsToHrsMins,
      sortDayInWeek,
      compactDuration,
      formatDate,
      formatDuration,
      to12HourBase,
      formatLongDateTime,
    }),
    [
      formatSecondToTime,
      secondsToHrsMinsDecimal,
      secondsToHrsMins,
      sortDayInWeek,
      compactDuration,
      formatDate,
      formatDuration,
      to12HourBase,
      formatLongDateTime,
    ]
  );
}
