import { GREY } from '@/minimals.cc/theme/palette';
import { css } from '@emotion/react';
import {
  ToggleButton,
  ToggleButtonProps,
  Typography,
  useTheme,
  Button,
  ButtonProps as MuiButtonProps,
} from '@mui/material';
import clsx from 'clsx';
import React, { ForwardedRef } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NextLink from './next-link';

type ButtonProps = MuiButtonProps & {
  component?: React.ElementType;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
};

export const PrimaryButton = React.forwardRef(function PrimaryButton(
  { className, ...props }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <Button
      className={clsx('PrimaryButton-root', className)}
      ref={ref}
      variant="contained"
      color="primary"
      {...props}
    />
  );
});

export const SecondaryButton = React.forwardRef(function SecondaryButton(
  { className, ...props }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const theme = useTheme();
  return (
    <Button
      className={clsx('SecondaryButton-root', className)}
      ref={ref}
      variant="outlined"
      color="inherit"
      css={css`
        color: ${theme.palette.text.primary};
        border: ${props.variant !== 'text'
          ? '1px solid rgba(145, 158, 171, 0.32)'
          : null};
        &:hover {
          border: ${props.variant !== 'text'
            ? '1px solid rgba(145, 158, 171, 0.32)'
            : null};
        }
      `}
      {...props}
    />
  );
});

export type ComplexButtonProps = {
  value?: string;
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
  secondaryWarn?: boolean;
  topRightIcon?: React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
};
export const ComplexButton = React.forwardRef(function ComplexButton(
  {
    primary,
    secondary,
    secondaryWarn,
    topRightIcon,
    selected,
    value,
    disabled,
    className,
    ...props
  }: ComplexButtonProps & Omit<ToggleButtonProps, keyof ComplexButtonProps>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <ToggleButton
      className={clsx('ComplexButton-root', className)}
      ref={ref}
      selected={selected}
      value={value || ''}
      color={selected ? 'primary' : undefined}
      disabled={disabled}
      css={css`
        flex-direction: column;
        min-height: 64px;
        min-width: 100px;
        position: relative;
        padding: 8px;
        &.Mui-disabled {
          border-color: ${GREY[200]};
          background-color: ${GREY[200]};
        }
      `}
      {...props}
    >
      {!!primary && (
        <Typography
          variant="subtitle1"
          sx={{
            px: topRightIcon ? 2 : undefined,
            color: selected ? 'primary' : 'text.primary',
            '.Mui-disabled &': { color: 'inherit' },
          }}
        >
          {primary}
        </Typography>
      )}
      {!!secondary && (
        <Typography
          variant="caption"
          sx={{
            pt: 0.5,
            color: secondaryWarn ? 'warning.dark' : undefined,
            '.Mui-disabled &': { color: 'inherit' },
          }}
          css={css`
            & > .MuiSvgIcon-fontSizeInherit {
              font-size: 16px;
              margin: -3px 0;
            }
          `}
        >
          {secondary}
        </Typography>
      )}
      {!!topRightIcon && (
        <span
          css={css`
            position: absolute;
            top: 0;
            right: 0;
            margin-right: 4px;
            opacity: 0.8;

            & > .MuiSvgIcon-root,
            & > svg {
              font-size: 16px;
              opacity: 0.8;
            }
          `}
        >
          {topRightIcon}
        </span>
      )}
    </ToggleButton>
  );
});

export const MobileBackBtn = React.forwardRef(function MobileBackBtn(
  { href, LinkComponent, ...props }: ButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const theme = useTheme();
  return (
    <Button
      ref={ref}
      variant="text"
      color="inherit"
      startIcon={<ArrowBackIosNewIcon style={{ fontSize: 20 }} />}
      css={css`
        color: ${theme.palette.text.primary};
        font-size: 21px;
        font-weight: 600;
        min-width: 40px;
        margin-left: -8px;
      `}
      LinkComponent={href && !LinkComponent ? NextLink : LinkComponent}
      href={href}
      {...props}
    />
  );
});
