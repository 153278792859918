import { LocaleCfg } from '@/common/hooks/use-locale-cfg';

/**
 * Format currency to {currency}{amount}
 * @param amount
 * @param locale
 *
 * @deprecated use `moneyFmt()` from `useMoneyFmt()` instead
 */
export const formatCurrency = (amount: number, locale: LocaleCfg) => {
  const fmt = new Intl.NumberFormat(locale['BCP 47'], {
    style: 'decimal',
    maximumFractionDigits: 2,
  });
  return locale.cPrefix + fmt.format(amount);
};
